import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDeletePropertyMutation } from "../../../hooks/property.hooks";
import CustomSignupButton from "./custom-signup-button";

const ExistedProperty = ({ property = {} }) => {
  const { propertyId = "", address: propertyAddress = "" } = property;

  const [focusInput, setFocusInput] = useState(false);

  const deleteProperty = useDeletePropertyMutation();

  const handleDeleteProperty = () => {
    deleteProperty.mutate(propertyId);
  };
  return (
    <div
      className="signup-existed-property"
      onMouseEnter={() => setFocusInput(true)}
      onMouseLeave={() => setFocusInput(false)}
    >
      <div className="signup-existed-property__address">
        <span>{propertyAddress}</span>
      </div>
      <div className="signup-existed-property__button">
        <CheckIcon />
      </div>
      <div
        className={classNames("custom-input-button-trash", "input-done", {
          "d-none": !focusInput || !propertyId,
        })}
      >
        <CustomSignupButton onClick={() => handleDeleteProperty()}>
          <DeleteIcon style={{ color: "#d0d0d0" }} />
        </CustomSignupButton>
      </div>
    </div>
  );
};

ExistedProperty.propTypes = {
  property: PropTypes.object,
};

export default ExistedProperty;
